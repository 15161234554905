/* PEROSNAL INFORMATION */
.personal_info_box {
  width: 100%;
}

.user_badge_box_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}
.user_badge_box_container .badge-box {
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.user_badge_box_container .badge-box .img_box {
  width: 60px;
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}
.user_badge_box_container .badge-box .text_box {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.user_badge_box_container .badge-box .text_box .name {
  text-align: center;
  font-size: 11px;
  font-weight: 500;
}
.user_badge_box_container .badge-box .text_box .description {
  text-align: center;
  font-size: 11px;
  height: 14px;
  width: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Connected Cards */
.conneted_card_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.conneted_card_box .conneted_card_card {
  width: 100%;
  padding: 20px;
  border-radius: 12px;
  background: var(--white);
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.conneted_card_box .conneted_card_card .cdc_left {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.conneted_card_box .conneted_card_card .cdc_left .card_name {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.conneted_card_box .conneted_card_card .cdc_left .card_number_box {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.conneted_card_box .conneted_card_card .cdc_left .card_number_box p {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.conneted_card_box .conneted_card_card .cdc_left .card_date {
  color: var(--text) !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
@media (max-width: 1440px) {
  .conneted_card_box .conneted_card_card .cdc_left .card_name {
    font-size: 16px;
  }
  .conneted_card_box .conneted_card_card .cdc_left .card_number_box p {
    font-size: 16px;
  }
  .conneted_card_box .conneted_card_card .cdc_left .card_date {
    font-size: 15px;
  }
}
/* ---------------------------------------------------- */
