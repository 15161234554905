.main_header_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_header {
  width: 100%;
  padding: 10px 30px;
  border-radius: 90px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: var(--white);
  box-shadow: 0px 4px 94px 0px rgba(0, 0, 0, 0.09);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.main_header .logo_box {
  height: 50px;
  width: max-content;
}
.main_header .logo_box img {
  height: 50px;
  width: auto;
  cursor: pointer;
}

.main_header .right_side {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.main_header .right_side img {
  height: 26px;
  cursor: pointer !important;
}

.main_header .search_input_box {
  width: 300px;
}

.main_header .search_input_box .ant-input-affix-wrapper {
  border-radius: 40px;
  background-color: var(--lightGray);
}

.main_header .search_input_box .ant-input-affix-wrapper img {
  height: 20px;
  width: auto;
  cursor: pointer;
}
.main_header .search_input_box .ant-input-affix-wrapper .ant-input {
  height: 38px !important;
  background-color: var(--lightGray);
}

.main_header .reward_box {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

/* PROFILE DORPDOWN */
.profile_dropdown_item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  /* padding: 16px 20px; */
  border-radius: 20px;
  cursor: pointer;
}

.profile_dropdown_item .active {
  background-color: var(--lightPink);
}
.profile_dropdown_item .icon_box {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7 !important;
  border-radius: 50%;
}
.profile_dropdown_item .icon_box img {
  width: 24px;
  height: 24px;
}
.profile_dropdown_item p {
  color: var(--black);
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-right: 20px;
}

.profile_dropdown .ant-dropdown-menu {
  padding: 15px !important;
}

@media (max-width: 1440px) {
  .profile_dropdown_item {
  }
  .profile_dropdown_item .icon_box {
    width: 40px;
    height: 40px;
  }
  .profile_dropdown_item p {
    font-size: 13px;
  }
}
@media (max-width: 850px) {
  .main_header .search_input_box {
    width: 100%;
  }
}
@media (max-width: 786px) {
  .main_header .search_input_box {
    display: none;
  }
}
@media (max-width: 600px) {
  .main_header {
    padding: 10px 20px;
  }
  .main_header .logo_box {
    height: 37px;
    width: max-content;
  }
  .main_header .logo_box img {
    height: 37px;
    width: auto;
  }
}

@media (max-width: 500px) {
  .main_header {
    border-radius: 16px;
    flex-direction: column;
    gap: 20px;
  }
  .main_header .right_side {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

/* @media only screen and (max-width: 400px) and (min-width: 375px) {
  .main_header .right_side {
    gap: 10px;
  }
} */
