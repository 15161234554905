.about_us_container {
  width: 100%;
  background-color: var(--lightGray);
}

.about_us_body {
  width: 100%;
  padding-top: 70px;
  padding-bottom: 200px;
}
.about_us_body .heading_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about_us_body .heading_box .heading {
  text-align: center;
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}
.about_us_body .heading_box .description {
  margin-top: 26px;
  color: var(--text);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  width: 1100px;
}

/* STORY SECTION */
.about_us_body .story_section {
  margin-top: 120px;
}
.about_us_body .story_section .story_left {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.about_us_body .story_section .story_left h2 {
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
.about_us_body .story_section .story_left p {
  color: var(--text);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  margin-top: 30px;
}
.about_us_body .story_section .story_right {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.about_us_body .story_section .story_right > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.about_us_body .story_section .story_right > div img {
  width: 100%;
}

/* OUR MISSION SECTION */
.about_us_body .our_mission_section {
  width: 100%;
  background-image: url("../../assets/images/our-mission.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 91px 58px;
  margin-top: 240px;
}
.about_us_body .our_mission_section h2 {
  color: var(--constantWhite);
  text-align: center;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-top: 270px;
}
.about_us_body .our_mission_section p {
  color: var(--constantWhite);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  margin-top: 30px;
}

/* BUSINESS SECTION */
.business_section {
  width: 100%;
  margin-top: 200px;
}

.business_section .business_goal_box {
  width: 100%;
  height: 100%;
  padding-block: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.business_section .business_goal_box h1 {
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  width: 400px;
}
.business_section .business_goal_box p {
  color: var(--text);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.business_section .business_card {
  width: 100%;
  background-color: var(--white);
  border-radius: 20px;
}
.business_section .business_card .image_box {
  width: 100%;
  padding-bottom: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.business_section .business_card .detail_box {
  padding: 26px;
}
.business_section .business_card .detail_box h2 {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
.business_section .business_card .detail_box p {
  color: var(--text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

/* CUSTOMER REVIEW SECTION */
.customer_review_section {
  width: 100%;
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.customer_review_section > h2 {
  text-align: center;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.customer_review_section .review_box {
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 120px;
}
.customer_review_section .review_box .review_left {
  width: 400px;
}
.customer_review_section .review_box .review_left .left_top {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.customer_review_section .review_box .review_left .left_top h2 {
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
.customer_review_section .review_box .review_left .left_top p {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.customer_review_section .review_box .review_left > p {
  margin-top: 36px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
}

.customer_review_section .review_box .review_right {
  width: 661px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.customer_review_section .review_box .review_right .row {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.customer_review_section .review_box .review_right .row > p {
  font-size: 20px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 28.402px;
}
.customer_review_section .review_box .review_right .row .number {
  width: 25px;
}
.customer_review_section .review_box .review_right .row .points {
  min-width: 55px;
}
.customer_review_section .ant-progress .ant-progress-success-bg,
.ant-progress .ant-progress-bg {
  /* clip-path: inset(0px 25% 0px 0px round 0px) !important; */
}
.customer_review_section .ant-progress-text {
  /* font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28.402px; */
}

@media (max-width: 1440px) {
  .about_us_body .heading_box .heading {
    font-size: 48px;
  }
  .about_us_body .heading_box .description {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
    width: 83%;
  }

  .about_us_body .story_section {
    margin-top: 100px;
  }
  .about_us_body .story_section .story_left h2 {
    font-size: 28px;
  }
  .about_us_body .story_section .story_left p {
    font-size: 16px;
  }

  .about_us_body .our_mission_section {
    padding: 40px 40px;
    margin-top: 100px;
  }
  .about_us_body .our_mission_section h2 {
    font-size: 28px;
    margin-top: 200px;
  }
  .about_us_body .our_mission_section p {
    font-size: 16px;
    margin-top: 20px;
  }

  .business_section {
    margin-top: 100px;
  }
  .business_section .business_card .detail_box {
    padding: 20px;
  }
  .business_section .business_card .detail_box h2 {
    font-size: 18px;
  }

  .customer_review_section {
    margin-top: 100px;
  }
  .customer_review_section > h2 {
    font-size: 28px;
  }
  .customer_review_section .review_box {
    margin-top: 30px;
  }

  .customer_review_section .review_box {
    gap: 40px;
  }

  .customer_review_section .review_box .review_left .left_top h2 {
    font-size: 28px;
  }
  .customer_review_section .review_box .review_left .left_top p {
    font-size: 16px;
  }
  .customer_review_section .review_box .review_left > p {
    font-size: 16px;
  }

  .customer_review_section .review_box .review_right {
    width: 400px;
  }
  .customer_review_section .review_box .review_right .row > p {
    font-size: 16px !important;
  }
}
@media (max-width: 1200px) {
  .business_section .business_goal_box h1 {
    width: 100%;
  }
}
@media (max-width: 1016px) {
  .customer_review_section .review_box .review_left .left_top {
    justify-content: center;
  }
  .customer_review_section .review_box .review_left > p {
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
  }
  .about_us_body {
    padding-bottom: 100px;
  }
}
@media (max-width: 768px) {
  .about_us_body .our_mission_section {
    padding: 30px 20px;
  }
  .about_us_body .our_mission_section h2 {
    font-size: 28px;
    margin-top: 0px;
  }
  .about_us_body .our_mission_section p {
    font-size: 15px;
    margin-top: 30px;
  }
}
@media (max-width: 600px) {
  .about_us_body .heading_box .description {
    width: 100%;
  }
  .business_section .business_goal_box h1 {
    font-size: 35px;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  .business_section .business_goal_box p {
    font-size: 17px;
  }
  .customer_review_section .review_box .review_left {
    width: 100%;
  }
  .customer_review_section .review_box .review_right {
    width: 100%;
    gap: 0px;
  }
  .business_section .business_goal_box {
    padding-block: 0px;
  }
  .about_us_body .heading_box .heading {
    font-size: 28px;
  }
}
@media (max-width: 360px) {
  .customer_review_section .review_box .review_left .left_top {
    flex-direction: column;
  }
}
