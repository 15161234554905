@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap");
/* font-family: "Sora", sans-serif; */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* font-family: 'Inter', sans-serif; */
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;1,9..40,100&display=swap");
/* font-family: 'DM Sans', sans-serif; */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Sora", sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.layoutPaddingInline {
  padding-inline: 80px;

}
.layoutPaddingBlock {
  padding-inline: 80px;
}
.customPadding {
  padding-block: 80px;
  width: 100%;
}

/* .ant-row.css-dev-only-do-not-override-18nx4ye{
  flex-flow:row; 
  overflow-y: scroll;
  scrollbar-width: none; 
  overflow-x: none;
} */

.main_card {
  display: flex;
  flex-flow: row;
  gap: 20px;
  overflow-y: scroll;
  scrollbar-width: none;
  overflow-x: none;
}

@media (max-width: 768px) {
  .layoutPaddingInline {
    padding-inline: 40px;
  }
  .customPadding {
    padding-block: 30px;
    width: 100%;
  }
}
@media (max-width: 600px) {
  .customPadding {
    padding-inline: 20px;
  }
}

button {
  border-radius: 90px !important;
}

.primaryButton {
  background: var(--mainGradient) !important;
  box-shadow: var(--boxShadow);
  font-family: "DM Sans", sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-transform: initial !important;
  border: none !important;
  outline: none !important;
  height: 40px;
  padding-inline: 20px;
}
.primaryButton:hover {
  background-color: var(--mainGradient) !important;
}
.emptyCart {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.outlinedButton {
  padding-inline: 20px;
  background: linear-gradient(#fff, #fff) padding-box,
    var(--mainGradient) border-box;
  border: 1px solid transparent;
  border-radius: 40px;
  display: inline-block;
  height: 38px;
  font-size: 14px;
  font-family: "DM Sans", sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-transform: initial !important;
}
.outlinedButton:hover {
  background: linear-gradient(#fff, #fff) padding-box,
    var(--mainGradient) border-box !important;
}

.whiteButton {
  font-family: "DM Sans", sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-transform: initial !important;
  border: none !important;
  outline: none !important;
  height: 40px;
  padding-inline: 20px;
  background: var(--white);
  box-shadow: none;
}
.whiteButton:hover {
  background: var(--white) !important;
}

.cursor {
  cursor: pointer !important;
}

.gradientText {
  font-size: 14px;
  background: var(--mainGradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.active_breadcrumb {
  color: var(--black);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.empty_cart {
  width: 100%;
  height: 215px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lightGreen {
  background-color: var(--lightGreen);
}
.lightGreen svg {
  color: var(--darkGreen);
}
.lightGreen span {
  color: var(--darkGreen);
}
.lightOrange {
  background-color: #ffeab1;
}
.lightOrange svg {
  color: #fab700;
}
.lightOrange span {
  color: #fab700;
}
.lightRed {
  background-color: rgb(255, 214, 214);
}
.lightRed svg {
  color: var(--mainRed);
}
.lightRed span {
  color: var(--mainRed) !important;
}

.lost_connection {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

/* BADGE */
/* ---------------------------------------------- */
.bagde_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow-x: auto;
  user-select: none;
}

.bagde_container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.bagde_container::-webkit-scrollbar {
  display: none;
}

.bagde_container .badge_box {
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}
.bagde_container .badge_box .image_box {
  width: 60px;
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}
.bagde_container .badge_box .text_box {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.bagde_container .badge_box .text_box .name {
  text-align: center;
  font-size: 11px;
  font-weight: 500;
}
.bagde_container .badge_box .text_box .description {
  text-align: center;
  font-size: 11px;
  height: 14px;
  width: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 400) {
  .groupWishImage {
    width: 200px;
  }
}
/* ---------------------------------------------- */
