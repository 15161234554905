.Product {
  background: var(--lightGray);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Product .customButton {
  color: var(--white);
  height: 50px;
  width: 170px;
  cursor: pointer;
  margin-bottom: 5rem;
}
.emptyProduct {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 6rem;
  margin-bottom: 6rem;
}
.emptyProduct img {
  max-width: 100%;
  height: auto;
  /* This ensures the aspect ratio is maintained */
  display: block;
  /* Remove extra space below the image */
  margin: 0 auto;
  /* Center the image within its container */
}
.btn_Category{
  border: 1px solid grey;
  padding: 8px 20px;
  border-radius: 7px;
  cursor: pointer;
  min-width: 180px;
  text-align: center;
  font-family: "sora";
  font-size: 15px;
}

.btn_Category_active{
color: rgb(251, 251, 253);
background-color: var(--mainBlue);
}
.btn_Category_main{
  display: flex;
  margin-top: 20px;
  width: 90%;
  flex-flow: row;
  margin: auto;
  overflow-y: scroll;
  overflow-x: none;
  scrollbar-width: none; 
  padding-left: 10px;
  overflow-x: none; 
  align-items: center;
  gap: 10px;
}


.product_search_input {
  display: flex;
  align-self: flex-end;
  align-items: center; 
  width: 40%;
}

.product_search_input .ant-input {
  width: 100%
}

.emptyProduct .emptyProductText {
  color: var(--black);
  text-align: center;
  font-family: Sora;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.Product .product_pagination_box {
  margin-bottom: 50px;
}

.back_to{
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 8px;
  cursor: pointer;
}




@media only screen and (max-width: 700px) {
  .btn_Category {
    min-width: 140px;
    font-size: 13px;
  }
  .product_search_input {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-block: 10px;
  }
}
@media only screen and (max-width: 450px) {
  .btn_Category {
    min-width: 130px;
    font-size: 12px;
    padding: 8px 4px;

  }
}