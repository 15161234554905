.setting_container_main {
  width: 100%;
  background-color: var(--lightGray);
  min-height: 100vh;
}
.setting_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 67px;
  margin-top: 30px;
  padding-bottom: 188px;
}
.setting_container .setting_left {
  width: 339px;
}
.setting_container .setting_right {
  flex: 1;
  width: calc(100% - 406px);
}

.setting_container .setting_heading {
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
.setting_container .setting_heading_small {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.setting_container_main .label {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 7px;
}

.setting_container .profile_image_header {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.setting_container .profile_image_header img {
  width: 60px;
}
.setting_container .profile_image_header p {
  font-size: 18px;
  font-weight: 600;
}

/* NOTIFICATION PAGE */
.notification_settings_Header_Container{
  display: flex;
  justify-content: space-between;
}
.notification_table_container{
  margin-top: 20px;
}
.notification_table_container table tr td:first-child {  
  width: 10%;
  text-align: center;
}
.notification_btn_main{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.notifiction_active{
  padding:10px 20px;
  border-radius: 10px; 
  background-color: #d9eff8;
  width: fit-content;
  font-weight: 600;
  cursor: pointer;
}
.notifiction_btn{
  padding:10px 20px;
  border-radius: 10px; 
  background-color:transparent;
  font-weight: 600;
  width: fit-content;
  cursor: pointer;
}
.notifiction_ReadBtn{
  border-radius: 10px; 
  background-color:transparent;
  font-weight: 600;
  height: 40px;
  width: fit-content;
  border: 1px solid black;
  cursor: pointer;
}

.notification_read_main{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.notification_modal_heading{
  color: var(--mainBlue);
}

.notification_dropdown_item {
  width: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  /* padding: 16px 20px; */
  border-radius: 20px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
}

.notification_dropdown_item .active {
  background-color: var(--lightPink);
}
.notification_dropdown_item .icon_box {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7 !important;
  border-radius: 50%;
}
.notification_dropdown_item .icon_box img {
  width: 24px;
  height: 24px;
}
.notification_dropdown_item p {
  color: var(--black);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-right: 20px;
}

.notification_dropdown_item .ant-dropdown-menu {
  padding: 15px !important;
}

.table-div{
  background: var(--white);
  box-shadow: 0px 4px 94px 0px rgba(0, 0, 0, 0.09);
  padding: 20px;
}


@media (max-width: 1440px) {
  .setting_container {
    gap: 40px;
    padding-bottom: 100px;
  }
  .setting_container .setting_left {
    width: 270px;
  }
  .setting_container .setting_right {
    flex: 1;
    width: calc(100% - 310px);
  }
  .setting_container .setting_heading {
    font-size: 26px;
  }
  .setting_container .setting_heading_small {
    font-size: 16px;
  }
}

@media (max-width: 1000px) {
  .setting_container .setting_left .side_tabs_box {
    display: none;
  }
  .setting_container {
    flex-direction: column;
    /* gap: 67px; */
  }
  .setting_container .setting_left {
    width: 100%;
  }
  .setting_container .setting_right {
    width: 100%;
  }
}
@media (max-width: 800px) {
  .setting_container {
    gap: 30px;
    margin-top: 10px;
    padding-bottom: 50px;
  }
  .notification_dropdown_item {
    width: 300px;
  }
}
@media (max-width: 600px) {
  .setting_container {
    gap: 30px;
    margin-top: 10px;
    padding-bottom: 50px;
  }
  .setting_container .setting_heading {
    font-size: 20px;
  }
  .notification_dropdown_item {
    width: 300px;
  }
}
