.contact {
    width: 100%;
    background-color: var(--lightGray);
    min-height: 100vh;
}

.contactContainer {
    width: 90%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    margin-left: 30px;
}

.contactContainer .left_container {
    flex: 1;
    height: inherit;
    display: flex;
    margin-left: 60px;
    width: 90%;
    align-items: flex-start;
    padding: 40px 20px 0;
    height: 100%;
}

.contactContainer .left_container .form_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

}

.contactContainer .left_container .form_wrapper .heading {
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.contactContainer .left_container .form_wrapper p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;

    /* width: 400px; */
    /* text-align: center; */
    margin-top: 10px;
}

.contactContainer .right_container {
    flex: 1;
    height: inherit;
    justify-content: flex-start;
    align-items: flex-start;

}

.contactContainer .right_container .right_container_wrapper {
    width: 100%;
    height: 100%;

}

.contactContainer .right_container .right_container_wrapper .map {

    height: 80%;
    width: 100%;
}
@media (max-width: 1024px) {
    .contactContainer .right_container .right_container_wrapper .map {

        height: 90%;
        width: 100%;
    }
}
@media (max-width: 768px) {
    .contactContainer {
        display: grid;
        flex-direction: column;
        /* height: 100%; */
    }
    

    .contactContainer .left_container {
        margin-left: 0;
        padding: 20px 0;
        width: 100%;
    }

    .contactContainer .right_container {
        margin-top: 20px;
        height: 100%;
    }

    .contactContainer .right_container .right_container_wrapper {
        /* width: 100%; */
        height: 100%;
        margin-bottom: 100%;
    }

    .contactContainer .right_container .right_container_wrapper .map {
        position: absolute;
        height: 90%;
        width: auto;
    }

}