.shareReward {
  display: grid;
  justify-content: center;
  align-items: center;
}

.rewardTextDiv {
  text-align: center;
}

.rewardTextDiv h4 {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.rewardTextDiv p {
  padding-top: 10px;
}

.rewardForm {
  margin-top: 20px;
}
/* .REWARD_MODAL_INPUT .ant-input{
    border-right: none !important;
} */
.REWARD_MODAL_INPUT .ant-input-group-addon {
  background-color: transparent !important;
}

.REWARD_MODAL .ant-modal-content {
  padding: 40px !important;
}

.REWARD_MODAL .exceed_limit_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center;
}
.REWARD_MODAL .exceed_limit_box p {
  color: red;
}

@media (max-width: 1440px) {
  .REWARD_MODAL .ant-modal-content {
    padding: 30px !important;
  }
}
