.cart {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  background-color: #f8f8f8 !important;
}

.cart .cartBreadcrumb {
  color: var(--black);
  font-family: Sora;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
  letter-spacing: 0.1px;
}
.amazon{
  display: flex;
  margin-top: 10px;
  gap: 5px;
}
.amazon .amz {
  font-size: 15px;
  font-weight: 600;
  color: black;
}
.main_amazon{
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
}

.amazon img{
  object-fit: contain;
}
.amazon_price{
  font-size: 20px;
  margin-top: 10px;
  font-weight: 500;
}

.cart .cardBorder {
  border: 1px solid #0000001a;
  border-radius: 20px;
  margin: 1rem 0;
}

/* .cart .empty_cart {
  width: 100%;
  height: 215px;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.cart .cardBorder .emptyCart {
  padding: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mostS {
  font-size: 25px;
  font-weight: 600;
  font-family: Sora;
  margin-bottom: 30px;
}

.cart_card {
  margin-top: 30px;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

}

/* .ant-row.css-dev-only-do-not-override-18nx4ye{
  flex-flow:row; 
  overflow-y: scroll;
  scrollbar-width: none; 
  overflow-x: none;
} */

/* .cart_card::-webkit-scrollbar{
  display: none;
} */

.card_main {
  border-radius: 20px;
  background-color: #fff;
  padding: 20px 20px 28px 20px;
  width: 320px;
  cursor: pointer;
}

.card_main h5 {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card_main p {
  color: var(--text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
}


.cart .cardBorder .emptyCart img {
  width: 20% !important;
}

.cart .cardBorder .emptyCartText {
  text-align: center;
  margin-top: 1rem;
  color: var(--darkGray);
}

@media(max-width: 600px) {
  .cart .cardBorder .emptyCart img {
    width: 40% !important;
  }
}