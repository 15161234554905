.main_footer_container {
  width: 100%;
  background-color: var(--black);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* SUBSCRIBE */
.main_footer_container .subscribe_box {
  width: 500px;
  padding-block: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main_footer_container .subscribe_box > h1 {
  color: var(--textField);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  line-height: 1.2;
}
.main_footer_container .subscribe_box > p {
  color: var(--textField);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin-top: 10px;
}
.main_footer_container .subscribe_box .input_email_box {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 350px;
  margin-top: 30px;
}
.main_footer_container .subscribe_box .input_email_box .ant-input {
  height: 40px;
  border-radius: 40px;
  padding-inline: 20px;
}

.main_footer_container .line {
  border-color: var(--constantBlack);
  width: 100%;
}

/* FOOTER TEXT HEADER */
.main_footer_container .footer-header { 
  padding-block: 30px;
  display: flex;
  justify-content: center;
  align-items: center; 
  gap: 20px;
  flex-direction: column;
}

.main_footer_container .footer-header .core-values {
  text-align: center;
}

.main_footer_container .footer-header .footer-address { 
  text-align: center;
  font-size: 14px;
}
.main_footer_container .footer-header .footer-address p {
  margin-block: 20px;
}

/* FOOTER SOCIAL LINK CONTAINER */
.main_footer_container .footer-link-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap:20px;
  /* background-color: red; */
}

.main_footer_container .footer-link-container .footer-link-start,
.main_footer_container .footer-link-container .footer-link-end {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #ffe4c4; */
  width: 100%;
  gap: 10px;
}

.main_footer_container .footer-link-container .footer-link-start .page-links,
.main_footer_container .footer-link-container .footer-link-end .page-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
  width: 100%;
}

.main_footer_container .footer-link-container .footer-link-start .page-links p,
.main_footer_container .footer-link-container .footer-link-end .page-links p {
  cursor: pointer;
}

.main_footer_container .footer-link-container .footer-link-start .line,
.main_footer_container .footer-link-container .footer-link-end .line {
  height: 2px;
  background-color: white;
}


.main_footer_container .footer-link-container .footer-social-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 400px;
  gap: 30px;
}

.main_footer_container .footer-link-container .footer-social-icon p:nth-child(3) {
  margin-top: 40px;
}



/* FOOTER BOTTOM */
.main_footer_container .footer_bottom {
  width: 100%;
  padding-block: 60px;
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.main_footer_container .footer_bottom .left {
  flex: 1;
  margin-right: 150px;
}
.main_footer_container .footer_bottom .center {
  margin-right: 150px;
}
.main_footer_container .footer_bottom .right {
  margin-right: 80px;
}

.main_footer_container .footer_bottom .left p,
.main_footer_container .footer_bottom .center p,
.main_footer_container .footer_bottom .right p {
  color: var(--textField);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}

.main_footer_container .footer_bottom .left,
.main_footer_container .footer_bottom .center,
.main_footer_container .footer_bottom .right {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.main_footer_container .footer_bottom .center p,
.main_footer_container .footer_bottom .right p {
  cursor: pointer;
}

.main_footer_container .footer_bottom .heading_top {
  color: var(--text) !important;
  cursor: default !important;
}

.main_footer_container .footer_bottom .links {
  color: var(--white) !important;
  cursor: pointer !important;
  text-decoration: none !important;
  scroll-behavior: smooth !important;
}

.main_footer_container .footer_bottom .description_text {
  width: 500px;
  font-size: 22px;
}

.main_footer_container .copyright_box {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.main_footer_container .copyright_box p {
  text-align: center;
  color: var(--textField);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  padding-bottom: 60px;
}

@media (max-width: 1150px) {
  .main_footer_container .footer_bottom .description_text {
    width: 100%;
  }
  .main_footer_container .footer_bottom .left {
    margin-right: 80px;
  }
  .main_footer_container .footer_bottom .center {
    margin-right: 80px;
  }
}

@media (max-width: 900px) {
  .main_footer_container .footer_bottom .left {
    margin-right: 0px;
  }
  .main_footer_container .footer_bottom .center {
    margin-right: 0px;
  }
  .main_footer_container .footer_bottom .right {
    margin-right: 0px;
  }
  .main_footer_container .footer-link-container {
    flex-direction: column;
  }
  .main_footer_container .footer-link-container .footer-link-start {
    order: 1;
  }
  .main_footer_container .footer-link-container .footer-link-end {
    order: 2;
    margin-top: 20px;
  }
  .main_footer_container .footer-link-container .footer-social-icon {
    order: 3;
  }
}

@media only screen and (max-width: 600px) {
  .main_footer_container {
    padding-inline: 20px;
  }
  .main_footer_container .subscribe_box {
    width: 100%;
  }
  .main_footer_container .subscribe_box > h1 {
    font-size: 30px;
  }
  .main_footer_container .subscribe_box .input_email_box {
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;
  }
  .main_footer_container .subscribe_box .input_email_box button {
    width: 50%;
  }
  .main_footer_container .footer_bottom {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .main_footer_container .footer_bottom .left {
    min-width: 280px;
  }
  .main_footer_container .footer_bottom .center {
    width: calc(100% - 150px);
  }
  .main_footer_container .footer_bottom .right {
    width: 100px;
  }
  .description_text{
    font-size: 16px;
  }
  .copyright_box{
    font-size: 14px;
  }
  .page-links{
    font-size: 16px !important;
    flex-direction: column !important;
    gap: 10px;
  }


}


@media only screen and (max-width: 430px) {
  .description_text{
    font-size: 12px;
  }
  .footer-address{
    font-size: 12px;
  }
  .copyright_box{
    font-size: 13px;
  }
  .main_footer_container .footer-link-container .footer-social-icon{
    gap: 20px !important;
  }
}

@media (max-width: 360px) {
  .main_footer_container .footer_bottom .center {
    width: 100%;
  }
  .main_footer_container .footer_bottom .right {
    width: 100%;
  }
  .main_footer_container .footer-link-container .footer-social-icon {
    width: 100%;
  }
  .page-links{
    font-size: 13px !important;
  }
  .copyright_box{
    font-size: 8px !important;
  }
}
