.category_card_small {
  width: 100px;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.category_card_small div {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.category_card_small p {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  margin-top: 10px;
}

/* ---------------------------------------------- */
.category_card_big {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.category_card_big div {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.category_card_big p {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  margin-top: 10px;
}
/* ---------------------------------------------- */

@media (max-width: 1440px) {
  .category_card_small {
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .category_card_small div {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }

  .category_card_big {
    width: 140px;
  }
  .category_card_big div {
    width: 140px;
    height: 140px;
  }
}

@media (max-width: 768px) {
  .category_card_big {
    width: 100px;
  }
  .category_card_big div {
    width: 100px;
    height: 100px;
  }
}
