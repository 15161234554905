/* TABLE */
table .ant-table-measure-row {
  display: none !important;
}
table thead tr > th {
  /* padding-block: 10px !important; */
  background-color: var(--white) !important;
  color: var(--text) !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
table tbody tr > td {
  /* padding-block: 4px !important; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: transparent;
}

.ant-table-wrapper .ant-table-thead > tr > td {
  background-color: var(--white) !important;
}

/* FORM */
.ant-form-item {
  margin-bottom: 10px !important;
}
.ant-form-item-label {
  padding-bottom: 3px !important;
}
.ant-form-item-label label {
  font-size: 16px !important;
}
.ant-form-item-required {
  font-size: 13px !important;
}

/* INPUT */
.ant-input {
  /* padding: 6px 11px !important; */
  border-radius: 6px;
  border-color: var(--inputBorder);
  height: 50px;
}
.ant-input::placeholder {
  font-size: 14px !important;
  color: var(--placeholder) !important;
}
.ant-select {
  height: 48px !important;
}

.select-placeholder {
  font-size: 13px !important;
  color: var(--placeholder) !important;
  font-family: "DM Sans", sans-serif;
}

.ant-input-affix-wrapper {
  border-radius: 6px;
  border-color: var(--inputBorder);
  padding: 0px 11px !important;
  /* height: 50px; */
}
.ant-input-affix-wrapper .ant-input {
  height: 48px !important;
}

.ant-picker {
  height: 50px;
}
.ant-picker-large .ant-picker-input > input {
  font-size: 14px !important;
  color: var(--placeholder) !important;
}

/* MODAL */
.ant-modal-title {
  font-size: 20px !important;
}
.ant-modal-footer {
  display: none !important;
}
