.Wishlist {
  background: var(--lightGray);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Wishlist .customButton {
  color: var(--white);
  height: 50px;
  width: 170px;
  cursor: pointer;
  margin-bottom: 5rem;
}

.Wishlist .emptyCart {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 6rem;
}

.Wishlist .emptyCart img {
  max-width: 100%;
  height: auto;
  /* This ensures the aspect ratio is maintained */
  display: block;
  /* Remove extra space below the image */
  margin: 0 auto;
  /* Center the image within its container */
}

.Wishlist .emptyCart .emptyCartText {
  color: var(--black);
  text-align: center;
  font-family: Sora;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  display: flex;
  width: 100%;
  height: 67.417px;
  flex-direction: column;
  justify-content: center;
  word-break: break-word;
}

.Wishlist .emptyCardHeading {
  color: var(--black);
  text-align: left;
  font-family: Sora;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 32px; */
  letter-spacing: -0.35px;
  text-transform: capitalize;
  padding: 1rem 0;
}

.Wishlist .heading_top_box {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

/* @media (max-width: 800px) {
    .Wishlist .customButton {
      margin-bottom: 30px !important;
    }
  } */
